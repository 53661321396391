import clsx from 'clsx';
import { Button, PlusOutlined } from 'core-ui';
import { ACTION } from 'src/constants/textAction';

interface ButtonCreateProps {
  onClick?: (event?: any) => void;
  className?: string;
  disabled?: boolean;
}
const ButtonCreate: React.FC<ButtonCreateProps> = ({
  onClick,
  className,
  disabled
}) => {
  return (
    <>
      <Button
        disabled={disabled}
        icon={<PlusOutlined />}
        type="primary"
        onClick={onClick}
        className={clsx(
          'bg-[#4759ff] hover:!bg-[#4759ff] rounded-sm',
          className,
          {
            'hover:!bg-[#f5f5f5]': disabled,
            'hover:!bg-[#4759ff]': !disabled
          }
        )}
      >
        {ACTION.CREATE}
      </Button>
    </>
  );
};
export default ButtonCreate;
