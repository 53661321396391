import { Layout, MenuProps } from 'core-ui';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Logo from './components/Logo';
import { MenuAdmin } from './components/menuAdmin';
import { useEffect, useRef, useState } from 'react';
import './style.scss';

export const menu: MenuProps['items'] = MenuAdmin?.map((item: any) => {
  const icon = item?.icon ? (
    <img
      className="text-[red]"
      src={item?.icon}
      alt="sidebar-icon"
      width="24"
      height="24"
    />
  ) : (
    ''
  );
  return {
    key: item?.key,
    icon: icon,
    label: item?.label,
    children: item?.children,
    disabled: item?.disabled
  };
});

const MainLayout = () => {
  const navigate = useNavigate();

  const transformRef = useRef<any>(null);

  const location = useLocation();

  const parentPathname =
    '/' +
    location?.pathname
      .split('/')
      .filter((item, index) => index === 1)
      .join('');
  const [menuSelected, setMenuSelected] = useState(parentPathname);

  useEffect(() => {
    const result = location?.pathname
      ?.split('/')
      .slice(0, 3)
      .join('/')
      .replace(/\/\d+$/, '')
      .replace(/\/(create|edit|detail)$/, '');
    setMenuSelected(result);
  }, [menuSelected, location]);

  return (
    <Layout
      headerContent={<Header />}
      logoContent={<Logo />}
      logoClassName="flex justify-center items-center max-md:hidden"
      menuItems={menu}
      menuProps={{
        selectedKeys: [menuSelected],
        style: {
          height: '100%',
          borderRight: 0,
          overflowY: 'scroll'
        },
        onSelect: (key: { key: string }) => {
          setMenuSelected(key?.key);
        },
        onClick: (key: { key: string }) => {
          navigate(`${key.key}`);
        }
      }}
      headerStyle={{
        overflowY: 'hidden'
      }}
      siderClassName="max-md:hidden"
      siderStyle={{
        overflowX: 'hidden'
      }}
      contentStyle={{
        overflowX: 'auto',
        overflowY: 'hidden'
      }}
      contentClassName="!bg-[#f2f2f2]"
      contentWrapperClassName="!p-0"
    >
      <Outlet />
    </Layout>
  );
};

export default MainLayout;
