import React, { useEffect, useState } from 'react';
import Timeline, {
  TimelineHeaders,
  DateHeader,
  CustomHeader
} from 'react-calendar-timeline';
import styles from './styles.module.scss';
import { convertDateToJapaneseFormat, FORMAT } from 'src/constants/datetime';
import clsx from 'clsx';
import { dayjs } from 'core-helpers';
import { RADIO_COLOR } from 'src/constants/common';
import { useSelector } from 'react-redux';
import { AuthState } from 'src/redux/authInfoSlice';
import { IUser } from 'src/models/user';
import scheduleSlice from 'src/redux/scheduleSlice';
import { useAppDispatch } from 'src/redux/hooks';
import {
  TimelineWeekGroupItem,
  TimelineWeekItem
} from '../../ScheduleWeek/CustomTimeLine';
import { Employee, tabSchedule, TypeTabScheduleAction } from '../..';

interface CustomTimeLineProps {
  showDrawEdit: () => void;
  listEmployeeCharge: Employee[];
  listScheduleData: any[];
  typeTabSchedule: TypeTabScheduleAction;
}

export interface RootState {
  schedule: {
    startTime: Date;
    endTime: Date;
    actionView: string;
    detailSchedule: {};
    statusActionTabView: boolean;
    statusPrevNextViewMonth?: boolean;
    currentTabSchedule?: string;
  };
}

const CustomTimeLine: React.FC<CustomTimeLineProps> = ({
  showDrawEdit,
  listEmployeeCharge,
  listScheduleData,
  typeTabSchedule
}) => {
  var keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end',
    groupLabelKey: 'title'
  };

  const { startTime, endTime } = useSelector(
    (state: RootState) => state.schedule
  );

  const auth: IUser | any = useSelector(
    (state: AuthState) => state?.auth?.user
  );

  const dispatch = useAppDispatch();

  const { setDetailSchedule } = scheduleSlice.actions;

  const [listScheduleDay, setListScheduleDay] =
    useState<TimelineWeekItem[]>(listScheduleData);

  const [listGroupResult, setListGroupResult] = useState<
    TimelineWeekGroupItem[]
  >([]);
  function getMarkerLeft({
    timelineOffsetLeft,
    timelineTimeEnd,
    timelineTimeStart,
    timelineWidth,
    markerDate,
    markerWidth = 0
  }: any) {
    return (
      timelineOffsetLeft +
      ((markerDate - timelineTimeStart) /
        (timelineTimeEnd - timelineTimeStart)) *
        timelineWidth -
      markerWidth / 2
    );
  }

  const listEmployee =
    listEmployeeCharge?.length > 0
      ? listEmployeeCharge
      : [{ id: auth?.id, title: auth?.name }];

  const listGroupCommon =
    listEmployeeCharge?.length > 0 ? listEmployeeCharge : [];

  useEffect(() => {
    if (Array.isArray(listScheduleData)) {
      const formattedData = listScheduleData?.map((item) => ({
        ...item,

        color: item.color
      }));
      setListScheduleDay(formattedData);
    }
  }, [listScheduleData]);

  useEffect(() => {
    const listGroup =
      typeTabSchedule?.includes(tabSchedule.Vehicle) ||
      typeTabSchedule?.includes(tabSchedule.Group) ||
      typeTabSchedule?.includes(tabSchedule.Participation)
        ? listGroupCommon
        : listEmployee;
    setListGroupResult(listGroup);
  }, [listScheduleData, typeTabSchedule]);

  return (
    <>
      <div className={styles.viewDay}>
        {startTime &&
        endTime &&
        listGroupResult?.length > 0 &&
        listScheduleData?.length > 0 ? (
          <Timeline
            groups={listGroupResult}
            items={listScheduleDay}
            keys={keys}
            stackItems
            sidebarWidth={120}
            visibleTimeStart={startTime}
            visibleTimeEnd={endTime}
            itemRenderer={({ item, itemContext, getItemProps }) => {
              let bgColorCustom = RADIO_COLOR.find(
                (element: { value?: number }) =>
                  element.value === Number(item?.color ?? 1)
              )?.bgColor;
              let colorCustom = RADIO_COLOR.find(
                (element: { value?: number }) =>
                  element.value === Number(item?.color ?? 1)
              )?.color;
              const differenceInMilliseconds = item?.end - item?.start;

              const differenceInMinutes =
                differenceInMilliseconds / (60 * 1000);

              return (
                <div
                  {...getItemProps({
                    style: {
                      background: bgColorCustom || '#0085FF',
                      color: colorCustom || 'white',
                      borderRadius: 8,
                      border: 'none',
                      overflow: differenceInMinutes === 30 ? 'hidden' : '',
                      cursor: 'pointer'
                    },
                    className: clsx('rct-item', styles.customItemContent),
                    onTouchStart: (event) => {
                      event.preventDefault();
                      dispatch(setDetailSchedule(item));
                      showDrawEdit();
                    },
                    onTouchEnd: (event) => {
                      event.preventDefault();
                    }
                  })}
                  onClick={() => {
                    dispatch(setDetailSchedule(item));
                    showDrawEdit();
                  }}
                  title={item.title}
                >
                  <div className="rct-item-content">{item.title}</div>
                </div>
              );
            }}
            onTimeChange={(
              _start: number,
              _end: number,
              updateScrollCanvas
            ) => {
              if (_start > startTime.getTime() && _end < endTime.getTime())
                updateScrollCanvas(_start, _end);
            }}
            className="!bg-white"
          >
            <TimelineHeaders className="timeline">
              <DateHeader
                className={clsx('bg-white', styles.dateHeader)}
                unit="primaryHeader"
                labelFormat={() => {
                  if (startTime) {
                    const day = dayjs(startTime).format(
                      FORMAT.FORMAT_DATE_UPPER_B
                    );
                    return convertDateToJapaneseFormat(day, 'date') || '';
                  }
                  return '';
                }}
              />
              <CustomHeader unit="hour">
                {({
                  headerContext: { intervals },
                  getRootProps,
                  getIntervalProps
                }: any) => {
                  if (!intervals || intervals.length === 0) {
                    return null;
                  }
                  const lastInterval = intervals[intervals.length - 1];
                  const timelineRight =
                    lastInterval.left +
                    getIntervalProps({ interval: lastInterval }).style.width;
                  const timelineOffsetLeft = intervals[0].left;

                  const timelineTimeStart = intervals[0].startTime.valueOf();
                  const timelineTimeEnd = lastInterval.endTime.valueOf();
                  const timelineWidth = timelineRight - timelineOffsetLeft;
                  const markerLeft = getMarkerLeft({
                    timelineTimeStart,
                    timelineTimeEnd,
                    timelineOffsetLeft,
                    timelineWidth,
                    markerWidth: 16
                  });

                  return (
                    <div
                      {...getRootProps({
                        style: { backgroundColor: 'lightblue' }
                      })}
                    >
                      {intervals.map(
                        (interval: { startTime: Date }, index: number) => {
                          return (
                            <>
                              <div
                                {...getIntervalProps({
                                  interval,
                                  style: {
                                    lineHeight: '30px',
                                    textAlign: 'center',
                                    borderRight: '1px solid #e8e8e8',
                                    cursor: 'pointer',
                                    backgroundColor: '#ffffff',
                                    left: markerLeft,
                                    transform: 'translateX(-2%)',
                                    fontWeight: '500'
                                  }
                                })}
                                key={index}
                              >
                                {dayjs(interval.startTime).format('HH')}
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  );
                }}
              </CustomHeader>
            </TimelineHeaders>
          </Timeline>
        ) : (
          <div>
            <Timeline
              groups={listGroupResult}
              items={listScheduleDay}
              keys={keys}
              stackItems
              sidebarWidth={120}
              visibleTimeStart={startTime}
              visibleTimeEnd={endTime}
              onTimeChange={(
                _start: number,
                _end: number,
                updateScrollCanvas
              ) => {
                if (_start > startTime.getTime() && _end < endTime.getTime())
                  updateScrollCanvas(_start, _end);
              }}
              className="!bg-white"
            >
              <TimelineHeaders className="timeline">
                <DateHeader
                  className={clsx('bg-white', styles.dateHeader)}
                  unit="primaryHeader"
                  labelFormat={() => {
                    if (startTime) {
                      const day = dayjs(startTime).format(
                        FORMAT.FORMAT_DATE_UPPER_B
                      );
                      return convertDateToJapaneseFormat(day, 'date') || '';
                    }
                    return '';
                  }}
                />
                <CustomHeader unit="hour">
                  {({
                    headerContext: { intervals },
                    getRootProps,
                    getIntervalProps
                  }: any) => {
                    if (!intervals || intervals.length === 0) {
                      return null;
                    }
                    const lastInterval = intervals[intervals.length - 1];
                    const timelineRight =
                      lastInterval.left +
                      getIntervalProps({ interval: lastInterval }).style.width;
                    const timelineOffsetLeft = intervals[0].left;

                    const timelineTimeStart = intervals[0].startTime.valueOf();
                    const timelineTimeEnd = lastInterval.endTime.valueOf();
                    const timelineWidth = timelineRight - timelineOffsetLeft;
                    const markerLeft = getMarkerLeft({
                      timelineTimeStart,
                      timelineTimeEnd,
                      timelineOffsetLeft,
                      timelineWidth,
                      markerWidth: 16
                    });

                    return (
                      <div
                        {...getRootProps({
                          style: { backgroundColor: 'lightblue' }
                        })}
                      >
                        {intervals.map(
                          (interval: { startTime: Date }, index: number) => {
                            return (
                              <>
                                <div
                                  {...getIntervalProps({
                                    interval,
                                    style: {
                                      lineHeight: '30px',
                                      textAlign: 'center',
                                      borderRight: '1px solid #e8e8e8',
                                      cursor: 'pointer',
                                      backgroundColor: '#ffffff',
                                      left: markerLeft,
                                      transform: 'translateX(-2%)',
                                      fontWeight: '500'
                                    }
                                  })}
                                  key={index}
                                >
                                  {dayjs(interval.startTime).format('HH')}
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    );
                  }}
                </CustomHeader>
              </TimelineHeaders>
            </Timeline>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTimeLine;
