import React from 'react';
import CustomTimeLine from './CustomTimeLine';
import 'react-calendar-timeline/lib/Timeline.css';
import CustomToolbarViewDayWeek from '../CustomToolbarViewDayWeek';
import { ScheduleEventI } from 'src/models/schedule';
import { Employee, TypeTabScheduleAction } from '..';

interface ScheduleDayProps {
  showDrawEdit: () => void;
  listEmployeeCharge: Employee[];
  listScheduleData: ScheduleEventI[];
  typeTabSchedule: TypeTabScheduleAction;
}

const ScheduleDay: React.FC<ScheduleDayProps> = ({
  showDrawEdit,
  typeTabSchedule,
  listEmployeeCharge,
  listScheduleData
}) => {
  return (
    <div className="bg-white mt-5 mx-5 pt-5 mb-8 max-md:overflow-x-scroll">
      <div className="max-md:min-w-[1000px]">
        <CustomToolbarViewDayWeek />
        <CustomTimeLine
          showDrawEdit={showDrawEdit}
          listEmployeeCharge={listEmployeeCharge}
          listScheduleData={listScheduleData}
          typeTabSchedule={typeTabSchedule}
        />
      </div>
    </div>
  );
};

export default ScheduleDay;
