import {
  dateHelper,
  dayjs,
  FormatTemplate,
  Locale,
  TimeZone
} from 'core-helpers';
import {
  activeViews,
  typeDate as typeDateCustom
} from 'src/pages/AdminManagement/Schedule';

export const FORMAT = {
  FORMAT_DATE_UPPER: 'YYYY/MM/DD',
  FORMAT_DATE_UPPER_B: 'YYYY-MM-DD',
  FORMAT_DATE_UPPER_DATE_TIME: 'yyyy/MM/dd HH:mm',
  FORMAT_DATE_UPPER_DATE_PICKER: 'yyyy/MM/dd',
  FORMAT_DATE_UPPER_DATE_TIME_B: 'YYYY/MM/DD HH:mm',
  FORMAT_MONTH_DAY_HOUR: 'MM月DD日HH:mm',
  FORMAT_MONTH_DAY: 'MM月DD日',
  FORMAT_DAY_HOUR: 'DD日HH:mm',
  FORMAT_DAY: 'DD日',
  FORMAT_DATE_LOW: 'YYYY/MM/DD',
  FORMAT_DATE_LOW_SUB: 'yyyy-mm-dd',
  DATE_FORMAT: 'YYYY/MM/DD',
  MONTH_FORMAT: 'YYYY/MM'
};

export type DisabledDateFunction = (
  current: dayjs.Dayjs,
  dates?: Date[]
) => boolean;

export const disabledDate: DisabledDateFunction = (current, dates) => {
  if (!dates || dates.length === 0) {
    return false;
  }
  const tooEarly = dates[0] && current.diff(dates[0], 'days') < 0;
  const tooLate = dates[1] && current.diff(dates[1], 'days') > 0;
  return tooEarly || tooLate;
};

const convertDayToJapanese = (day: string): string => {
  const dayMap: { [key: string]: string } = {
    Mo: '月',
    Tu: '火',
    We: '水',
    Th: '木',
    Fr: '金',
    Sa: '土',
    Su: '日'
  };
  return dayMap[day] || day;
};

export const convertDateToJapaneseFormat = (
  dateString: string | undefined,
  type?: string
) => {
  if (!dateString) {
    return;
  }

  const date = dayjs(dateString);
  const dayOfWeek = convertDayToJapanese(date.format('dd'));
  const dayOfMonth = date.format('D');

  return date.isValid()
    ? `${dayOfWeek}${!type?.includes('date') ? '\n ' : '\n'}${dayOfMonth} `
    : '';
};

const formatDate = (date: dayjs.Dayjs, format: string) => {
  return dateHelper.formatDate({
    date: date.valueOf(),
    format,
    locale: Locale.JA,
    timeZone: TimeZone['Asia/Tokyo']
  });
};

export const parsedDateMonth = (
  dateString: string,
  typeDate?: string,
  isSameDay?: boolean,
  isAllDay?: boolean
) => {
  const date = dayjs(dateString);

  if (!date.isValid()) return null;

  const format = isAllDay
    ? FormatTemplate['YYYY/MM/DD']
    : FormatTemplate['YYYY/MM/DD HH:mm:ss'];
  const formattedDate = formatDate(date, format);

  const start = isAllDay
    ? formatDate(date.startOf('day'), format)
    : formattedDate;
  const end = isSameDay
    ? formattedDate
    : isAllDay
      ? formatDate(date.endOf('day'), format)
      : formattedDate;

  if (typeDate?.includes(typeDateCustom.Start)) return start;
  if (typeDate?.includes(typeDateCustom.End)) return end;

  return null;
};

export const onPrevNextDay = (date: Date, days: number): Date => {
  const newDate = dayjs(date).add(days, 'day').toDate();
  return newDate;
};

export const getStartEndDateOfWeek = (date: Date) => {
  const start = dayjs(date).startOf('week').add(1, 'day').toDate();
  const end = dayjs(date).endOf('week').add(1, 'day').toDate();
  return { start, end };
};

export const getStartEndDateOfMonth = (date: dayjs.Dayjs) => {
  const startOfMonth = date.startOf('month').format(FORMAT.FORMAT_DATE_UPPER_B);
  const endOfMonth = date.endOf('month').format(FORMAT.FORMAT_DATE_UPPER_B);
  return { startOfMonth, endOfMonth };
};

export const parsedDateDayWeek = (
  dateString: string,
  typeDate?: string,
  view?: string,
  isSameDate?: boolean
) => {
  const parsedDate = dayjs(dateString);
  const format = FormatTemplate['YYYY-MM-DD HH:mm:ss'];
  const isHafHour = dayjs(dateString).minute() === 30;

  if (!parsedDate.isValid()) {
    return null;
  }

  const formattedDate = dayjs(formatDate(parsedDate, format));
  const isStart = typeDate?.includes(typeDateCustom.Start);

  if (view === activeViews.Week) {
    return isStart
      ? formattedDate.startOf('day').valueOf()
      : formattedDate.endOf('day').valueOf();
  } else if (view === activeViews.Day) {
    return isStart
      ? formattedDate.valueOf()
      : isSameDate
        ? formattedDate.add(isHafHour ? 0 : 1, 'hour').valueOf()
        : formattedDate.endOf('day').add(1, 'day').valueOf();
  } else {
    return null;
  }
};

export const currentDate = new Date();

export const currentYear = currentDate.getFullYear();
export const currentMonth = currentDate.getMonth();
export const currentDay = currentDate.getDate();
